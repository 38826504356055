import React from 'react';
import ReactDOM from 'react-dom/client';
import './index.css';
import App from './App';
import reportWebVitals from './reportWebVitals';
import Websites from './pages/Websites';
import WebsitesList from './pages/WebsitesList';
import EditWebsite from './pages/EditWebsite';
import CheckWebsites from './pages/CheckWebsites';
import {WebsiteIssues} from './pages';
import './assets/scss/public.scss';
import {HashRouter,Route,Routes,Link,NavLink} from 'react-router-dom'
import 'antd/dist/antd.min.js';
import { ConfigProvider } from 'antd';


const root = ReactDOM.createRoot(document.getElementById('root'));
root.render(
  <ConfigProvider>
  <React.StrictMode>
    <HashRouter>
      <div className="nav-links" >
        <NavLink to="/" >Add New Website</NavLink>
        <NavLink to="/list" >Websites List</NavLink>
        <NavLink to="/check" >Check Websites</NavLink>
      </div>
      <Routes>
        <Route exact element={<Websites />} path="/" />
        <Route element={<WebsitesList />} path="/list" />
        <Route element={<EditWebsite />} path="/edit/:id" />
        <Route element={<WebsiteIssues />} path="/issues/:id" />
        <Route element={<CheckWebsites />} path="/check" />
      </Routes>
    </HashRouter>
  </React.StrictMode>
  </ConfigProvider>
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();