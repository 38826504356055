import React,{useEffect,useState} from 'react';
import {CallApi} from './../helpers';
import {Button} from 'antd';
import {Loading} from './../components';
import {Link} from 'react-router-dom';

var checkedNumbers = 0;
var currentRowId = 0;
var queue = []

const setQueue = (data=[]) => {
	queue = data
}

const CheckWebsites = () => {
	
	const [isLoading,setIsLoading] = useState(true)
	const [data,setData] = useState([])
	const [renderQueue,setRenderQueue] = useState(0)


	const fetchData = () => {
		setIsLoading(true)
		CallApi.get("/websites",{params:{types:[1]}}).then(res=>{
			setData(res.data)
		}).catch(err=>{
		}).finally(()=>{
			setIsLoading(false)
		})
	}

	const fetchQueueRow = () => {
		const row = data[checkedNumbers]
		const id = row?.id
		if(row &&  id){
			if(checkedNumbers==0){
				setQueue([...queue,{ id,is_loading:true,status:"loading",row }])
				setRenderQueue(1)
			}
			CallApi.get(`/websites/${id}/check`).then(res=>{
				queueNext(res.data);
			}).catch(err=>{
				console.log(err)
				alert("error")
			}).finally(()=>{
				setIsLoading(false)
			})
		}
	}

	const queueNext = (qdata) => {

		var currentRow = queue[queue.length-1];
		currentRow.is_loading=false;
		currentRow.status="success";
		currentRow = {...currentRow,...qdata}
		checkedNumbers += 1;

		//next queue
		const row = data[checkedNumbers]
		const nextRow = { id:row?.id,is_loading:true,status:"loading",row}

		const queueRows = queue.filter(qu=>qu.id!=currentRow.id)
		setQueue(row ? [...queueRows,currentRow,nextRow] : [...queueRows,currentRow])


		setRenderQueue(Math.random())
		
		// if(checkedNumbers<1){
			fetchQueueRow()
		// }

	}

	useEffect(()=>{
		console.log(renderQueue)
	},[renderQueue])

	const printQueueResult = () => {
		setIsLoading(true)
		CallApi.post(`/websites/mail`,{data:queue}).then(res=>{
			// alert("Email Successfully Sent.")
			console.log("asd",res.data)
		}).catch(err=>{
			alert("error")
		}).finally(()=>{
			setIsLoading(false)
		})
	}

	const startQueue = async () => {
		setQueue([])
		checkedNumbers = 0
		fetchQueueRow()
	}

	useEffect(()=>{
		fetchData()
	},[])

	return (
		<div className="websites-container">
			{
				isLoading
				&&<Loading />
			}
			<div className="">
				<Button onClick={startQueue} >Start Checking...</Button>
				{
					queue.length>0
					&&<Button onClick={printQueueResult} >Mail Result</Button>
				}
			</div>
			{
				queue.length>0
				&&<div className="website-check__wrapper" >
					{
						queue.map(qu=>(
							<div className="website-check__row">
								<div className="website-check__col website-check__colId">{qu.id}</div>
								<div className="website-check__col website-check__colName">{qu.row.name}</div>
								{/* <div className="website-check__col website-check__colPhone">{qu.row.phone}</div> */}
								<div className="website-check__col website-check__colUrl">{qu.row.url}</div>
								<div className="website-check__col website-check__colSpans">
									{
										qu.is_loading
										?<img src="https://acegif.com/wp-content/uploads/loading-45.gif" width={25} />
										:<>

											{
												(qu.logs && qu.logs.errors_count && qu.logs.errors_count>0)
												?<Link target="_" to={`/issues/${qu.row.id}`} ><span className="website-check__errorSpan" >{qu.logs.errors_count}</span></Link>
												:<></>
											}

											{
												(qu.logs && qu.logs.warnings_count && qu.logs.warnings_count>0)
												?<Link target="_" to={`/issues/${qu.row.id}`} ><span className="website-check__warningSpan" >{qu.logs.warnings_count}</span></Link>
												:<></>
											}

											{
												(qu.logs && qu.logs.warnings_count==0 && qu.logs.errors_count==0)
												?<span target="_" className="website-check__checkSpan" >✓</span>
												:<></>
											}
										</>
									}
								</div>
							</div>
						))
					}
				</div>
			}

		</div>
	)
}

export default CheckWebsites;