import React from 'react';

const Loading = () => {
	return (
		<div className='loading-modal' >
			<div className="lds-facebook"><div></div><div></div><div></div></div>
		</div>
	)
}

export default Loading;