import React,{useEffect,useState} from 'react';
import Select from 'react-select'

const WebsitesSelect = (props) => (
  <Select defaultValue={17} {...props} />
)

const WebsiteForm = (props) => {

	// const [websitesOptions,setWebsitesOptions] = useState([])
	const {data,formSubmit,setData,websitesOptions} = props;
	const [valueFeilds,setValueFeilds] = useState([
		{name:"phone[]",value:''}
	])

	const setDataPhones = () => {

		if(data.phones){
			let phones = []
			data.phones.map(phone=>{
				phones.push({name:"phone[]",value:phone})
			})
			console.log(data.phones)
			setValueFeilds(phones)
		}
	}



	useEffect(()=>{
		setDataPhones()		
	},[data])

	const defaultSelect = () => {
		const result = websitesOptions.filter(f=>f.value==data.parent_id)
		if(result.length>0){
			return result[0].label;
			// return {label:result[0].label,value:result[0].value}
		}
		return ""
	}

	const setPhoneValue = (e,index) => {
		const values = [...valueFeilds]
		values[index].value = e.target.value;
		setValueFeilds(values)
	}

	const removePhones = (index) => {
		const values = [...valueFeilds]
		values.splice(index,1)
		setValueFeilds(values)
		// console.log()
		// console.log(values[index])
		// console.log(values)
	}

	return (
		<form onReset={()=>{
			if(!data.id){
				setValueFeilds([
					{name:"phone[]",value:''}
				])
			}
		}} className="new-website-form" onSubmit={formSubmit} >
			<div className="">
				<select name="type" required="required" onChange={select => {
					setData({...data,parent_id:null,type:select.target.value}) 
				}} >
					<option value="1" selected={data.type==1} >Website</option>
					<option value="2" selected={data.type==2} >PPC</option>
					<option value="3" selected={data.type==3} >Page</option>
				</select>
			</div>
			{
				(data.type && (data.type==2 || data.type==3))
				&&<div className="" style={{marginBottom:20}} >
					<WebsitesSelect defaultValue={ data.parent ? {label:data.parent.name,value:data.parent.id} : undefined } options={websitesOptions} onChange={(parent_id)=>setData({...data,parent_id:parent_id.value})} />
				</div>
			}
			{
				(!data.type || data.type==1 || data.type==null || data.type==3 || data.type==2 )
				&&<div className="block-input">
					<input placeholder="Name..." required="required" defaultValue={data.name} name="name" />
				</div>
			}
			<div className="block-input">
				<input placeholder="Url..." onBlur={props.onUrlChange} defaultValue={data.url} required="required" type="url" name="url" />
			</div>
			{
				valueFeilds.map((row,index)=>(
					<div className="phone-numbers">
						<input onChange={(e)=>setPhoneValue(e,index)} placeholder="Phone..."  value={row.value} required="required" type="phone" name={row.name} />	
						{
							index>0
							?<button type='button' style={{color:"red"}} onClick={()=>{
								removePhones(index)
							}} >-</button>
							:<button type='button' style={{color:"green"}} onClick={()=>{
								setValueFeilds([...valueFeilds,{name:"phone[]",value:''}])
							}} >+</button>
						}
					</div>
				))
			}
			{
				valueFeilds.length<1
				&&<div>
					<button 
						type='button' 
						onClick={()=>{
							setValueFeilds([...valueFeilds,{name:"phone[]",value:''}])
						}}
					>Add New Phone</button>
				</div>
			}
			<div className="" >
				<label>Pattern</label><br />
				<div style={{width:"100%"}} >
					<textarea style={{width:"100%"}} placeholder="Pattern..." name="pattern" defaultValue={data.pattern||`/^\\+?[1-9][0-9]{7,14}$/`} ></textarea>
				</div>
			</div>
			<div className="">
				<button type="submit" value="Save" >Save</button>
			</div>
		</form>
	)
}

export default WebsiteForm;