import React,{useEffect,useState} from 'react';
import {CallApi} from './../helpers';
import Select from 'react-select'
import {Loading,WebsiteForm} from './../components';
import {useParams} from 'react-router-dom';


const EditWebsite = () => {

	const [websitesOptions,setWebsitesOptions] = useState([])
	const [isLoading,setIsLoading] = useState(true)
	const [rowData,setRowData] = useState(null)
	const [data,setData] = useState({})
	const [websiteLinks,setLinks] = useState([])
	const [isUpdated,setIsUpdated] = useState(false)
	const {id} = useParams()


	const sortAndSetWebsitesOptions = (data) => {
		let rows = [];
		data.map(datarow=>{
			rows.push({value:datarow.id,label:datarow.name,})
		})
		setWebsitesOptions(rows)
	}

	const fetchWebsites = () => {
		setIsLoading(true)
		CallApi.get("/websites",{params:{types:[1]}}).then(res=>{
			sortAndSetWebsitesOptions(res.data)
		}).catch(err=>{
		}).finally(()=>{
			setIsLoading(false)
		})
	}

	const fetchData = () => {
		fetchRow()
	}

	const fetchRow = () => {
		setIsLoading(true)
		CallApi.get(`/websites/${id}`).then(res=>{
			fetchWebsites()
			setRowData(res.data)
			setData({...data,...res.data})
			setLinks(res.data.pages)
		}).catch(err=>{
			alert(0)
		}).finally(()=>{
			setIsLoading(false)
		})
	}

	useEffect(()=>{

		fetchData()

	},[])


	const formSubmit = (e) =>{
		e.preventDefault()
		const {name,url,pattern} = e.target;

	
		var submiteData = {...data,pages:websiteLinks,name:name.value,url:url.value,pattern:pattern.value}
		
		
		let phones = []
		if(e.target["phone[]"]){

			if(e.target["phone[]"].length){
				e.target["phone[]"].forEach(element => {
					phones.push(element.value)
				});
			}else{
				phones.push(e.target["phone[]"].value)
			}

			submiteData.phone = [...phones]

		}
		if(isUpdated){
			submiteData.is_updated = 1;
		}

		setIsLoading(true)

		CallApi.post(`/websites/${id}/update`,submiteData).then(res=>{
			alert("Wesbite Successfully updated!")
			// e.target.reset()
			// fetchData()
			// setData({})
		}).catch(err=>{
			console.log(err)
			alert("Error! please try again.")
		}).finally(()=>{
			setIsLoading(false)
		});

	}

	const onUrlChange = (a) => {
		if(a.target.value.length>0 && a.target.value!=data.url){
			setIsUpdated(1)
			setIsLoading(true)
			CallApi.get("/check/check?link="+a.target.value).then(res=>{
				setLinks(res.data)
			}).catch(err=>{
				setLinks([])
			}).finally(()=>{
				setIsLoading(false)
			})

		}
	}

	return (
		<div className="websites-container addnew-website">
			{
				isLoading
				&&<Loading />
			}
			<WebsiteForm data={data} onUrlChange={onUrlChange} setData={setData} formSubmit={formSubmit} websitesOptions={websitesOptions} />
			{
				websiteLinks.length>0
				&&<div className="website-links" >
					<ul>
						{websiteLinks.map(link=>(
							<li><a href={link} target="_" >{link}</a></li>
						))}
					</ul>
				</div>
			}
		</div>
	)
}

export default EditWebsite;