import React,{useEffect,useState} from 'react';
import {CallApi} from './../helpers';
import {Loading,WebsiteForm} from './../components';


const Websites = () => {

	const [websitesOptions,setWebsitesOptions] = useState([])
	const [isLoading,setIsLoading] = useState(true)
	const [websiteLinks,setLinks] = useState([])
	const [data,setData] = useState({})


	const sortAndSetWebsitesOptions = (data) => {
		let rows = [];
		data.map(datarow=>{
			rows.push({value:datarow.id,label:datarow.name,})
		})
		setWebsitesOptions(rows)
	}

	const fetchData = () => {
		setIsLoading(true)
		CallApi.get("/websites",{params:{types:[1]}}).then(res=>{
			sortAndSetWebsitesOptions(res.data)
		}).catch(err=>{
		}).finally(()=>{
			setIsLoading(false)
		})
	}

	useEffect(()=>{

		fetchData()

	},[])


	const formSubmit = (e) =>{
		e.preventDefault()

		// console.log(e.target.name)
		// return 0
		if(!isLoading){
			const {name,url,pattern,phone} = e.target;

			const submiteData = {pages:websiteLinks,name:name.value,url:url.value,pattern:pattern.value,...data}
			let phones = []
			
			if(e.target["phone[]"]){
				
				if(e.target["phone[]"].length){
					e.target["phone[]"].forEach(element => {
						phones.push(element.value)
					});
				}else{
					phones.push(e.target["phone[]"].value)
				}
				
				submiteData.phone = [...phones]

			}

			setIsLoading(true)

			CallApi.post("/websites/store",submiteData).then(res=>{
				alert("Wesbite Successfully added!")
				e.target.reset()
				fetchData()
				setData({})
				setLinks([])
			}).catch(err=>{
				console.log(err)
				alert("Error! please try again.")
			}).finally(()=>{
				setIsLoading(false)
			});
		}

	}

	const onUrlChange = (a) => {
		if(a.target.value.length>0){
			setIsLoading(true)
			CallApi.get("/check/check?link="+a.target.value).then(res=>{
				setLinks(res.data)
			}).catch(err=>{
				setLinks([])
			}).finally(()=>{
				setIsLoading(false)
			})

		}
	}

	return (
		<div className="websites-container addnew-website">
			{
				isLoading
				&&<Loading />
			}
			<WebsiteForm data={data} onUrlChange={onUrlChange} setData={setData} formSubmit={formSubmit} websitesOptions={websitesOptions} />
			{
				websiteLinks.length>0
				&&<div className="website-links" >
					<ul>
						{websiteLinks.map(link=>(
							<li><a href={link} target="_" >{link}</a></li>
						))}
					</ul>
				</div>
			}
		</div>
	)
}

export default Websites;