import React,{useEffect,useState,useRef} from 'react';
import {CallApi} from './../helpers';
import {Button} from 'antd';
import {Loading} from './../components';
import {useParams} from 'react-router-dom';


var checkedNumbers = 0;
var currentRowId = 0;
var queue = []

var ddata = {
    "total_rows": 184,
    "total_links": 21,
    "current_page": 1,
    "row": {
        "id": 3654,
        "name": "Ankinlaw",
        "url": "https://ankinlaw.com/",
        "auto_added": null,
        "pattern": "/^\\+?[1-9][0-9]{7,14}$/",
        "type": 1,
        "phone": "3126000000",
        "parent_id": null,
        "created_at": "2023-04-17T22:40:41.000000Z",
        "updated_at": "2023-04-17T22:40:41.000000Z",
        "links": [
            {
                "href": "tel:3126000000",
                "phone": "3126000000",
                "text": "(312) 600-0000"
            },
            {
                "href": "tel:3126000000",
                "phone": "3126000000",
                "text": "CALL(312) 600-0000"
            },
            {
                "href": "tel:3126000000",
                "phone": "3126000000",
                "text": "312-600-0000"
            },
            {
                "href": "tel:8446000000",
                "phone": "8446000000",
                "text": "(844) 600-0000"
            },
            {
                "href": "tel:3124624082",
                "phone": "3124624082",
                "text": "(312) 462-4082"
            },
            {
                "href": "tel:8446000000",
                "phone": "8446000000",
                "text": "(844) 600-0000"
            },
            {
                "href": "tel:2246000000",
                "phone": "2246000000",
                "text": "(224) 600-0000"
            },
            {
                "href": "tel:8726000000",
                "phone": "8726000000",
                "text": "(872) 600-0000"
            },
            {
                "href": "tel:7086000000",
                "phone": "7086000000",
                "text": "(708) 600-0000"
            }
        ],
        "logs": {
            "errors_count": 4,
            "warnings_count": 0
        },
        "phones": [
            "3126000000",
            "8446000000",
            "2246000000",
            "8726000000",
            "7086000000",
            "3124624082"
        ]
    },
    "links": [
        {
            "id": 3655,
            "name": "Ankinlaw Page",
            "url": "https://ankinlaw.com/can-hair-straighteners-cause-cancer/",
            "auto_added": 1,
            "pattern": "/^\\+?[1-9][0-9]{7,14}$/",
            "type": 3,
            "phone": "3126000000",
            "parent_id": 3654,
            "created_at": "2023-04-17T22:40:41.000000Z",
            "updated_at": "2023-04-17T22:40:41.000000Z",
            "links": [
                {
                    "href": "tel:3126000000",
                    "phone": "3126000000",
                    "text": "(312) 600-0000"
                },
                {
                    "href": "tel:3126000000",
                    "phone": "3126000000",
                    "text": "CALL(312) 600-0000"
                },
                {
                    "href": "tel:3126000000",
                    "phone": "3126000000",
                    "text": "312-600-0000"
                },
                {
                    "href": "tel:3126000000",
                    "phone": "3126000000",
                    "text": "312-600-0000"
                },
                {
                    "href": "tel:8446000000",
                    "phone": "8446000000",
                    "text": "(844) 600-0000"
                },
                {
                    "href": "tel:3124624082",
                    "phone": "3124624082",
                    "text": "(312) 462-4082"
                },
                {
                    "href": "tel:8446000000",
                    "phone": "8446000000",
                    "text": "(844) 600-0000"
                },
                {
                    "href": "tel:2246000000",
                    "phone": "2246000000",
                    "text": "(224) 600-0000"
                },
                {
                    "href": "tel:8726000000",
                    "phone": "8726000000",
                    "text": "(872) 600-0000"
                },
                {
                    "href": "tel:7086000000",
                    "phone": "7086000000",
                    "text": "(708) 600-0000"
                }
            ],
            "logs": {
                "errors_count": 4,
                "warnings_count": 0
            }
        },
        {
            "id": 3656,
            "name": "Ankinlaw Page",
            "url": "https://ankinlaw.com/what-is-the-first-step-when-injured-by-a-defective-product/",
            "auto_added": 1,
            "pattern": "/^\\+?[1-9][0-9]{7,14}$/",
            "type": 3,
            "phone": "3126000000",
            "parent_id": 3654,
            "created_at": "2023-04-17T22:40:41.000000Z",
            "updated_at": "2023-04-17T22:40:41.000000Z",
            "links": [
                {
                    "href": "tel:3126000000",
                    "phone": "3126000000",
                    "text": "(312) 600-0000"
                },
                {
                    "href": "tel:3126000000",
                    "phone": "3126000000",
                    "text": "CALL(312) 600-0000"
                },
                {
                    "href": "tel:3126000000",
                    "phone": "3126000000",
                    "text": "312-600-0000"
                },
                {
                    "href": "tel:3126000000",
                    "phone": "3126000000",
                    "text": "312-600-0000"
                },
                {
                    "href": "tel:8446000000",
                    "phone": "8446000000",
                    "text": "(844) 600-0000"
                },
                {
                    "href": "tel:3124624082",
                    "phone": "3124624082",
                    "text": "(312) 462-4082"
                },
                {
                    "href": "tel:8446000000",
                    "phone": "8446000000",
                    "text": "(844) 600-0000"
                },
                {
                    "href": "tel:2246000000",
                    "phone": "2246000000",
                    "text": "(224) 600-0000"
                },
                {
                    "href": "tel:8726000000",
                    "phone": "8726000000",
                    "text": "(872) 600-0000"
                },
                {
                    "href": "tel:7086000000",
                    "phone": "7086000000",
                    "text": "(708) 600-0000"
                }
            ],
            "logs": {
                "errors_count": 4,
                "warnings_count": 0
            }
        },
        {
            "id": 3657,
            "name": "Ankinlaw Page",
            "url": "https://ankinlaw.com/what-are-the-types-of-product-liability-claims/",
            "auto_added": 1,
            "pattern": "/^\\+?[1-9][0-9]{7,14}$/",
            "type": 3,
            "phone": "3126000000",
            "parent_id": 3654,
            "created_at": "2023-04-17T22:40:41.000000Z",
            "updated_at": "2023-04-17T22:40:41.000000Z",
            "links": [
                {
                    "href": "tel:3126000000",
                    "phone": "3126000000",
                    "text": "(312) 600-0000"
                },
                {
                    "href": "tel:3126000000",
                    "phone": "3126000000",
                    "text": "CALL(312) 600-0000"
                },
                {
                    "href": "tel:3126000000",
                    "phone": "3126000000",
                    "text": "312-600-0000"
                },
                {
                    "href": "tel:3126000000",
                    "phone": "3126000000",
                    "text": "312-600-0000"
                },
                {
                    "href": "tel:8446000000",
                    "phone": "8446000000",
                    "text": "(844) 600-0000"
                },
                {
                    "href": "tel:3124624082",
                    "phone": "3124624082",
                    "text": "(312) 462-4082"
                },
                {
                    "href": "tel:8446000000",
                    "phone": "8446000000",
                    "text": "(844) 600-0000"
                },
                {
                    "href": "tel:2246000000",
                    "phone": "2246000000",
                    "text": "(224) 600-0000"
                },
                {
                    "href": "tel:8726000000",
                    "phone": "8726000000",
                    "text": "(872) 600-0000"
                },
                {
                    "href": "tel:7086000000",
                    "phone": "7086000000",
                    "text": "(708) 600-0000"
                }
            ],
            "logs": {
                "errors_count": 4,
                "warnings_count": 0
            }
        },
        {
            "id": 3658,
            "name": "Ankinlaw Page",
            "url": "https://ankinlaw.com/what-is-an-unreasonably-dangerous-product/",
            "auto_added": 1,
            "pattern": "/^\\+?[1-9][0-9]{7,14}$/",
            "type": 3,
            "phone": "3126000000",
            "parent_id": 3654,
            "created_at": "2023-04-17T22:40:41.000000Z",
            "updated_at": "2023-04-17T22:40:41.000000Z",
            "links": [
                {
                    "href": "tel:3126000000",
                    "phone": "3126000000",
                    "text": "(312) 600-0000"
                },
                {
                    "href": "tel:3126000000",
                    "phone": "3126000000",
                    "text": "CALL(312) 600-0000"
                },
                {
                    "href": "tel:3126000000",
                    "phone": "3126000000",
                    "text": "312-600-0000"
                },
                {
                    "href": "tel:3126000000",
                    "phone": "3126000000",
                    "text": "312-600-0000"
                },
                {
                    "href": "tel:8446000000",
                    "phone": "8446000000",
                    "text": "(844) 600-0000"
                },
                {
                    "href": "tel:3124624082",
                    "phone": "3124624082",
                    "text": "(312) 462-4082"
                },
                {
                    "href": "tel:8446000000",
                    "phone": "8446000000",
                    "text": "(844) 600-0000"
                },
                {
                    "href": "tel:2246000000",
                    "phone": "2246000000",
                    "text": "(224) 600-0000"
                },
                {
                    "href": "tel:8726000000",
                    "phone": "8726000000",
                    "text": "(872) 600-0000"
                },
                {
                    "href": "tel:7086000000",
                    "phone": "7086000000",
                    "text": "(708) 600-0000"
                }
            ],
            "logs": {
                "errors_count": 4,
                "warnings_count": 0
            }
        },
        {
            "id": 3659,
            "name": "Ankinlaw Page",
            "url": "https://ankinlaw.com/how-to-know-if-youre-eligible-for-a-class-action-lawsuit-payout/",
            "auto_added": 1,
            "pattern": "/^\\+?[1-9][0-9]{7,14}$/",
            "type": 3,
            "phone": "3126000000",
            "parent_id": 3654,
            "created_at": "2023-04-17T22:40:41.000000Z",
            "updated_at": "2023-04-17T22:40:41.000000Z",
            "links": [
                {
                    "href": "tel:3126000000",
                    "phone": "3126000000",
                    "text": "(312) 600-0000"
                },
                {
                    "href": "tel:3126000000",
                    "phone": "3126000000",
                    "text": "CALL(312) 600-0000"
                },
                {
                    "href": "tel:3126000000",
                    "phone": "3126000000",
                    "text": "312-600-0000"
                },
                {
                    "href": "tel:3126000000",
                    "phone": "3126000000",
                    "text": "312-600-0000"
                },
                {
                    "href": "tel:8446000000",
                    "phone": "8446000000",
                    "text": "(844) 600-0000"
                },
                {
                    "href": "tel:3124624082",
                    "phone": "3124624082",
                    "text": "(312) 462-4082"
                },
                {
                    "href": "tel:8446000000",
                    "phone": "8446000000",
                    "text": "(844) 600-0000"
                },
                {
                    "href": "tel:2246000000",
                    "phone": "2246000000",
                    "text": "(224) 600-0000"
                },
                {
                    "href": "tel:8726000000",
                    "phone": "8726000000",
                    "text": "(872) 600-0000"
                },
                {
                    "href": "tel:7086000000",
                    "phone": "7086000000",
                    "text": "(708) 600-0000"
                }
            ],
            "logs": {
                "errors_count": 4,
                "warnings_count": 0
            }
        },
        {
            "id": 3660,
            "name": "Ankinlaw Page",
            "url": "https://ankinlaw.com/liability-for-food-poisoning/",
            "auto_added": 1,
            "pattern": "/^\\+?[1-9][0-9]{7,14}$/",
            "type": 3,
            "phone": "3126000000",
            "parent_id": 3654,
            "created_at": "2023-04-17T22:40:41.000000Z",
            "updated_at": "2023-04-17T22:40:41.000000Z",
            "links": [
                {
                    "href": "tel:3126000000",
                    "phone": "3126000000",
                    "text": "(312) 600-0000"
                },
                {
                    "href": "tel:3126000000",
                    "phone": "3126000000",
                    "text": "CALL(312) 600-0000"
                },
                {
                    "href": "tel:3126000000",
                    "phone": "3126000000",
                    "text": "312-600-0000"
                },
                {
                    "href": "tel:3126000000",
                    "phone": "3126000000",
                    "text": "312-600-0000"
                },
                {
                    "href": "tel:8446000000",
                    "phone": "8446000000",
                    "text": "(844) 600-0000"
                },
                {
                    "href": "tel:3124624082",
                    "phone": "3124624082",
                    "text": "(312) 462-4082"
                },
                {
                    "href": "tel:8446000000",
                    "phone": "8446000000",
                    "text": "(844) 600-0000"
                },
                {
                    "href": "tel:2246000000",
                    "phone": "2246000000",
                    "text": "(224) 600-0000"
                },
                {
                    "href": "tel:8726000000",
                    "phone": "8726000000",
                    "text": "(872) 600-0000"
                },
                {
                    "href": "tel:7086000000",
                    "phone": "7086000000",
                    "text": "(708) 600-0000"
                }
            ],
            "logs": {
                "errors_count": 4,
                "warnings_count": 0
            }
        },
        {
            "id": 3661,
            "name": "Ankinlaw Page",
            "url": "https://ankinlaw.com/how-to-claim-emotional-injury-for-a-car-accident/",
            "auto_added": 1,
            "pattern": "/^\\+?[1-9][0-9]{7,14}$/",
            "type": 3,
            "phone": "3126000000",
            "parent_id": 3654,
            "created_at": "2023-04-17T22:40:41.000000Z",
            "updated_at": "2023-04-17T22:40:41.000000Z",
            "links": [
                {
                    "href": "tel:3126000000",
                    "phone": "3126000000",
                    "text": "(312) 600-0000"
                },
                {
                    "href": "tel:3126000000",
                    "phone": "3126000000",
                    "text": "CALL(312) 600-0000"
                },
                {
                    "href": "tel:3126000000",
                    "phone": "3126000000",
                    "text": "312-600-0000"
                },
                {
                    "href": "tel:3126000000",
                    "phone": "3126000000",
                    "text": "312-600-0000"
                },
                {
                    "href": "tel:8446000000",
                    "phone": "8446000000",
                    "text": "(844) 600-0000"
                },
                {
                    "href": "tel:3124624082",
                    "phone": "3124624082",
                    "text": "(312) 462-4082"
                },
                {
                    "href": "tel:8446000000",
                    "phone": "8446000000",
                    "text": "(844) 600-0000"
                },
                {
                    "href": "tel:2246000000",
                    "phone": "2246000000",
                    "text": "(224) 600-0000"
                },
                {
                    "href": "tel:8726000000",
                    "phone": "8726000000",
                    "text": "(872) 600-0000"
                },
                {
                    "href": "tel:7086000000",
                    "phone": "7086000000",
                    "text": "(708) 600-0000"
                }
            ],
            "logs": {
                "errors_count": 4,
                "warnings_count": 0
            }
        },
        {
            "id": 3662,
            "name": "Ankinlaw Page",
            "url": "https://ankinlaw.com/what-does-car-accident-insurance-cover-in-illinois/",
            "auto_added": 1,
            "pattern": "/^\\+?[1-9][0-9]{7,14}$/",
            "type": 3,
            "phone": "3126000000",
            "parent_id": 3654,
            "created_at": "2023-04-17T22:40:41.000000Z",
            "updated_at": "2023-04-17T22:40:41.000000Z",
            "links": [
                {
                    "href": "tel:3126000000",
                    "phone": "3126000000",
                    "text": "(312) 600-0000"
                },
                {
                    "href": "tel:3126000000",
                    "phone": "3126000000",
                    "text": "CALL(312) 600-0000"
                },
                {
                    "href": "tel:3126000000",
                    "phone": "3126000000",
                    "text": "312-600-0000"
                },
                {
                    "href": "tel:3126000000",
                    "phone": "3126000000",
                    "text": "312-600-0000"
                },
                {
                    "href": "tel:8446000000",
                    "phone": "8446000000",
                    "text": "(844) 600-0000"
                },
                {
                    "href": "tel:3124624082",
                    "phone": "3124624082",
                    "text": "(312) 462-4082"
                },
                {
                    "href": "tel:8446000000",
                    "phone": "8446000000",
                    "text": "(844) 600-0000"
                },
                {
                    "href": "tel:2246000000",
                    "phone": "2246000000",
                    "text": "(224) 600-0000"
                },
                {
                    "href": "tel:8726000000",
                    "phone": "8726000000",
                    "text": "(872) 600-0000"
                },
                {
                    "href": "tel:7086000000",
                    "phone": "7086000000",
                    "text": "(708) 600-0000"
                }
            ],
            "logs": {
                "errors_count": 4,
                "warnings_count": 0
            }
        },
        {
            "id": 3663,
            "name": "Ankinlaw Page",
            "url": "https://ankinlaw.com/do-you-have-to-go-to-court-for-a-car-accident/",
            "auto_added": 1,
            "pattern": "/^\\+?[1-9][0-9]{7,14}$/",
            "type": 3,
            "phone": "3126000000",
            "parent_id": 3654,
            "created_at": "2023-04-17T22:40:41.000000Z",
            "updated_at": "2023-04-17T22:40:41.000000Z",
            "links": [
                {
                    "href": "tel:3126000000",
                    "phone": "3126000000",
                    "text": "(312) 600-0000"
                },
                {
                    "href": "tel:3126000000",
                    "phone": "3126000000",
                    "text": "CALL(312) 600-0000"
                },
                {
                    "href": "tel:3126000000",
                    "phone": "3126000000",
                    "text": "312-600-0000"
                },
                {
                    "href": "tel:3126000000",
                    "phone": "3126000000",
                    "text": "312-600-0000"
                },
                {
                    "href": "tel:8446000000",
                    "phone": "8446000000",
                    "text": "(844) 600-0000"
                },
                {
                    "href": "tel:3124624082",
                    "phone": "3124624082",
                    "text": "(312) 462-4082"
                },
                {
                    "href": "tel:8446000000",
                    "phone": "8446000000",
                    "text": "(844) 600-0000"
                },
                {
                    "href": "tel:2246000000",
                    "phone": "2246000000",
                    "text": "(224) 600-0000"
                },
                {
                    "href": "tel:8726000000",
                    "phone": "8726000000",
                    "text": "(872) 600-0000"
                },
                {
                    "href": "tel:7086000000",
                    "phone": "7086000000",
                    "text": "(708) 600-0000"
                }
            ],
            "logs": {
                "errors_count": 4,
                "warnings_count": 0
            }
        },
        {
            "id": 3664,
            "name": "Ankinlaw Page",
            "url": "https://ankinlaw.com/should-i-get-a-lawyer-for-a-car-accident-that-was-my-fault/",
            "auto_added": 1,
            "pattern": "/^\\+?[1-9][0-9]{7,14}$/",
            "type": 3,
            "phone": "3126000000",
            "parent_id": 3654,
            "created_at": "2023-04-17T22:40:41.000000Z",
            "updated_at": "2023-04-17T22:40:41.000000Z",
            "links": [
                {
                    "href": "tel:3126000000",
                    "phone": "3126000000",
                    "text": "(312) 600-0000"
                },
                {
                    "href": "tel:3126000000",
                    "phone": "3126000000",
                    "text": "CALL(312) 600-0000"
                },
                {
                    "href": "tel:3126000000",
                    "phone": "3126000000",
                    "text": "312-600-0000"
                },
                {
                    "href": "tel:3126000000",
                    "phone": "3126000000",
                    "text": "312-600-0000"
                },
                {
                    "href": "tel:8446000000",
                    "phone": "8446000000",
                    "text": "(844) 600-0000"
                },
                {
                    "href": "tel:3124624082",
                    "phone": "3124624082",
                    "text": "(312) 462-4082"
                },
                {
                    "href": "tel:8446000000",
                    "phone": "8446000000",
                    "text": "(844) 600-0000"
                },
                {
                    "href": "tel:2246000000",
                    "phone": "2246000000",
                    "text": "(224) 600-0000"
                },
                {
                    "href": "tel:8726000000",
                    "phone": "8726000000",
                    "text": "(872) 600-0000"
                },
                {
                    "href": "tel:7086000000",
                    "phone": "7086000000",
                    "text": "(708) 600-0000"
                }
            ],
            "logs": {
                "errors_count": 4,
                "warnings_count": 0
            }
        },
        {
            "id": 3665,
            "name": "Ankinlaw Page",
            "url": "https://ankinlaw.com/why-should-i-call-a-lawyer-before-my-insurance-company-after-a-car-accident/",
            "auto_added": 1,
            "pattern": "/^\\+?[1-9][0-9]{7,14}$/",
            "type": 3,
            "phone": "3126000000",
            "parent_id": 3654,
            "created_at": "2023-04-17T22:40:41.000000Z",
            "updated_at": "2023-04-17T22:40:41.000000Z",
            "links": [
                {
                    "href": "tel:3126000000",
                    "phone": "3126000000",
                    "text": "(312) 600-0000"
                },
                {
                    "href": "tel:3126000000",
                    "phone": "3126000000",
                    "text": "CALL(312) 600-0000"
                },
                {
                    "href": "tel:3126000000",
                    "phone": "3126000000",
                    "text": "312-600-0000"
                },
                {
                    "href": "tel:3126000000",
                    "phone": "3126000000",
                    "text": "312-600-0000"
                },
                {
                    "href": "tel:8446000000",
                    "phone": "8446000000",
                    "text": "(844) 600-0000"
                },
                {
                    "href": "tel:3124624082",
                    "phone": "3124624082",
                    "text": "(312) 462-4082"
                },
                {
                    "href": "tel:8446000000",
                    "phone": "8446000000",
                    "text": "(844) 600-0000"
                },
                {
                    "href": "tel:2246000000",
                    "phone": "2246000000",
                    "text": "(224) 600-0000"
                },
                {
                    "href": "tel:8726000000",
                    "phone": "8726000000",
                    "text": "(872) 600-0000"
                },
                {
                    "href": "tel:7086000000",
                    "phone": "7086000000",
                    "text": "(708) 600-0000"
                }
            ],
            "logs": {
                "errors_count": 4,
                "warnings_count": 0
            }
        },
        {
            "id": 3666,
            "name": "Ankinlaw Page",
            "url": "https://ankinlaw.com/can-you-sue-a-nurse-for-medical-malpractice/",
            "auto_added": 1,
            "pattern": "/^\\+?[1-9][0-9]{7,14}$/",
            "type": 3,
            "phone": "3126000000",
            "parent_id": 3654,
            "created_at": "2023-04-17T22:40:41.000000Z",
            "updated_at": "2023-04-17T22:40:41.000000Z",
            "links": [
                {
                    "href": "tel:3126000000",
                    "phone": "3126000000",
                    "text": "(312) 600-0000"
                },
                {
                    "href": "tel:3126000000",
                    "phone": "3126000000",
                    "text": "CALL(312) 600-0000"
                },
                {
                    "href": "tel:3126000000",
                    "phone": "3126000000",
                    "text": "312-600-0000"
                },
                {
                    "href": "tel:3126000000",
                    "phone": "3126000000",
                    "text": "312-600-0000"
                },
                {
                    "href": "tel:8446000000",
                    "phone": "8446000000",
                    "text": "(844) 600-0000"
                },
                {
                    "href": "tel:3124624082",
                    "phone": "3124624082",
                    "text": "(312) 462-4082"
                },
                {
                    "href": "tel:8446000000",
                    "phone": "8446000000",
                    "text": "(844) 600-0000"
                },
                {
                    "href": "tel:2246000000",
                    "phone": "2246000000",
                    "text": "(224) 600-0000"
                },
                {
                    "href": "tel:8726000000",
                    "phone": "8726000000",
                    "text": "(872) 600-0000"
                },
                {
                    "href": "tel:7086000000",
                    "phone": "7086000000",
                    "text": "(708) 600-0000"
                }
            ],
            "logs": {
                "errors_count": 4,
                "warnings_count": 0
            }
        },
        {
            "id": 3667,
            "name": "Ankinlaw Page",
            "url": "https://ankinlaw.com/how-to-prove-medical-malpractice/",
            "auto_added": 1,
            "pattern": "/^\\+?[1-9][0-9]{7,14}$/",
            "type": 3,
            "phone": "3126000000",
            "parent_id": 3654,
            "created_at": "2023-04-17T22:40:41.000000Z",
            "updated_at": "2023-04-17T22:40:41.000000Z",
            "links": [
                {
                    "href": "tel:3126000000",
                    "phone": "3126000000",
                    "text": "(312) 600-0000"
                },
                {
                    "href": "tel:3126000000",
                    "phone": "3126000000",
                    "text": "CALL(312) 600-0000"
                },
                {
                    "href": "tel:3126000000",
                    "phone": "3126000000",
                    "text": "312-600-0000"
                },
                {
                    "href": "tel:3126000000",
                    "phone": "3126000000",
                    "text": "312-600-0000"
                },
                {
                    "href": "tel:8446000000",
                    "phone": "8446000000",
                    "text": "(844) 600-0000"
                },
                {
                    "href": "tel:3124624082",
                    "phone": "3124624082",
                    "text": "(312) 462-4082"
                },
                {
                    "href": "tel:8446000000",
                    "phone": "8446000000",
                    "text": "(844) 600-0000"
                },
                {
                    "href": "tel:2246000000",
                    "phone": "2246000000",
                    "text": "(224) 600-0000"
                },
                {
                    "href": "tel:8726000000",
                    "phone": "8726000000",
                    "text": "(872) 600-0000"
                },
                {
                    "href": "tel:7086000000",
                    "phone": "7086000000",
                    "text": "(708) 600-0000"
                }
            ],
            "logs": {
                "errors_count": 4,
                "warnings_count": 0
            }
        },
        {
            "id": 3668,
            "name": "Ankinlaw Page",
            "url": "https://ankinlaw.com/what-does-a-summary-judgement-mean-in-a-medical-malpractice-suit/",
            "auto_added": 1,
            "pattern": "/^\\+?[1-9][0-9]{7,14}$/",
            "type": 3,
            "phone": "3126000000",
            "parent_id": 3654,
            "created_at": "2023-04-17T22:40:41.000000Z",
            "updated_at": "2023-04-17T22:40:41.000000Z",
            "links": [
                {
                    "href": "tel:3126000000",
                    "phone": "3126000000",
                    "text": "(312) 600-0000"
                },
                {
                    "href": "tel:3126000000",
                    "phone": "3126000000",
                    "text": "CALL(312) 600-0000"
                },
                {
                    "href": "tel:3126000000",
                    "phone": "3126000000",
                    "text": "312-600-0000"
                },
                {
                    "href": "tel:3126000000",
                    "phone": "3126000000",
                    "text": "312-600-0000"
                },
                {
                    "href": "tel:8446000000",
                    "phone": "8446000000",
                    "text": "(844) 600-0000"
                },
                {
                    "href": "tel:3124624082",
                    "phone": "3124624082",
                    "text": "(312) 462-4082"
                },
                {
                    "href": "tel:8446000000",
                    "phone": "8446000000",
                    "text": "(844) 600-0000"
                },
                {
                    "href": "tel:2246000000",
                    "phone": "2246000000",
                    "text": "(224) 600-0000"
                },
                {
                    "href": "tel:8726000000",
                    "phone": "8726000000",
                    "text": "(872) 600-0000"
                },
                {
                    "href": "tel:7086000000",
                    "phone": "7086000000",
                    "text": "(708) 600-0000"
                }
            ],
            "logs": {
                "errors_count": 4,
                "warnings_count": 0
            }
        },
        {
            "id": 3669,
            "name": "Ankinlaw Page",
            "url": "https://ankinlaw.com/should-i-file-a-medical-malpractice-lawsuit-for-brachial-plexus-injuries/",
            "auto_added": 1,
            "pattern": "/^\\+?[1-9][0-9]{7,14}$/",
            "type": 3,
            "phone": "3126000000",
            "parent_id": 3654,
            "created_at": "2023-04-17T22:40:41.000000Z",
            "updated_at": "2023-04-17T22:40:41.000000Z",
            "links": [
                {
                    "href": "tel:3126000000",
                    "phone": "3126000000",
                    "text": "(312) 600-0000"
                },
                {
                    "href": "tel:3126000000",
                    "phone": "3126000000",
                    "text": "CALL(312) 600-0000"
                },
                {
                    "href": "tel:3126000000",
                    "phone": "3126000000",
                    "text": "312-600-0000"
                },
                {
                    "href": "tel:3126000000",
                    "phone": "3126000000",
                    "text": "312-600-0000"
                },
                {
                    "href": "tel:8446000000",
                    "phone": "8446000000",
                    "text": "(844) 600-0000"
                },
                {
                    "href": "tel:3124624082",
                    "phone": "3124624082",
                    "text": "(312) 462-4082"
                },
                {
                    "href": "tel:8446000000",
                    "phone": "8446000000",
                    "text": "(844) 600-0000"
                },
                {
                    "href": "tel:2246000000",
                    "phone": "2246000000",
                    "text": "(224) 600-0000"
                },
                {
                    "href": "tel:8726000000",
                    "phone": "8726000000",
                    "text": "(872) 600-0000"
                },
                {
                    "href": "tel:7086000000",
                    "phone": "7086000000",
                    "text": "(708) 600-0000"
                }
            ],
            "logs": {
                "errors_count": 4,
                "warnings_count": 0
            }
        },
        {
            "id": 3670,
            "name": "Ankinlaw Page",
            "url": "https://ankinlaw.com/uber-accident-claims/",
            "auto_added": 1,
            "pattern": "/^\\+?[1-9][0-9]{7,14}$/",
            "type": 3,
            "phone": "3126000000",
            "parent_id": 3654,
            "created_at": "2023-04-17T22:40:41.000000Z",
            "updated_at": "2023-04-17T22:40:41.000000Z",
            "links": [
                {
                    "href": "tel:3126000000",
                    "phone": "3126000000",
                    "text": "(312) 600-0000"
                },
                {
                    "href": "tel:3126000000",
                    "phone": "3126000000",
                    "text": "CALL(312) 600-0000"
                },
                {
                    "href": "tel:3126000000",
                    "phone": "3126000000",
                    "text": "312-600-0000"
                },
                {
                    "href": "tel:3126000000",
                    "phone": "3126000000",
                    "text": "312-600-0000"
                },
                {
                    "href": "tel:8446000000",
                    "phone": "8446000000",
                    "text": "(844) 600-0000"
                },
                {
                    "href": "tel:3124624082",
                    "phone": "3124624082",
                    "text": "(312) 462-4082"
                },
                {
                    "href": "tel:8446000000",
                    "phone": "8446000000",
                    "text": "(844) 600-0000"
                },
                {
                    "href": "tel:2246000000",
                    "phone": "2246000000",
                    "text": "(224) 600-0000"
                },
                {
                    "href": "tel:8726000000",
                    "phone": "8726000000",
                    "text": "(872) 600-0000"
                },
                {
                    "href": "tel:7086000000",
                    "phone": "7086000000",
                    "text": "(708) 600-0000"
                }
            ],
            "logs": {
                "errors_count": 4,
                "warnings_count": 0
            }
        },
        {
            "id": 3671,
            "name": "Ankinlaw Page",
            "url": "https://ankinlaw.com/how-do-car-insurance-companies-investigate-accident-claims/",
            "auto_added": 1,
            "pattern": "/^\\+?[1-9][0-9]{7,14}$/",
            "type": 3,
            "phone": "3126000000",
            "parent_id": 3654,
            "created_at": "2023-04-17T22:40:41.000000Z",
            "updated_at": "2023-04-17T22:40:41.000000Z",
            "links": [
                {
                    "href": "tel:3126000000",
                    "phone": "3126000000",
                    "text": "(312) 600-0000"
                },
                {
                    "href": "tel:3126000000",
                    "phone": "3126000000",
                    "text": "CALL(312) 600-0000"
                },
                {
                    "href": "tel:3126000000",
                    "phone": "3126000000",
                    "text": "312-600-0000"
                },
                {
                    "href": "tel:3126000000",
                    "phone": "3126000000",
                    "text": "312-600-0000"
                },
                {
                    "href": "tel:8446000000",
                    "phone": "8446000000",
                    "text": "(844) 600-0000"
                },
                {
                    "href": "tel:3124624082",
                    "phone": "3124624082",
                    "text": "(312) 462-4082"
                },
                {
                    "href": "tel:8446000000",
                    "phone": "8446000000",
                    "text": "(844) 600-0000"
                },
                {
                    "href": "tel:2246000000",
                    "phone": "2246000000",
                    "text": "(224) 600-0000"
                },
                {
                    "href": "tel:8726000000",
                    "phone": "8726000000",
                    "text": "(872) 600-0000"
                },
                {
                    "href": "tel:7086000000",
                    "phone": "7086000000",
                    "text": "(708) 600-0000"
                }
            ],
            "logs": {
                "errors_count": 4,
                "warnings_count": 0
            }
        },
        {
            "id": 3672,
            "name": "Ankinlaw Page",
            "url": "https://ankinlaw.com/general-duty-clause-violations/",
            "auto_added": 1,
            "pattern": "/^\\+?[1-9][0-9]{7,14}$/",
            "type": 3,
            "phone": "3126000000",
            "parent_id": 3654,
            "created_at": "2023-04-17T22:40:41.000000Z",
            "updated_at": "2023-04-17T22:40:41.000000Z",
            "links": [
                {
                    "href": "tel:3126000000",
                    "phone": "3126000000",
                    "text": "(312) 600-0000"
                },
                {
                    "href": "tel:3126000000",
                    "phone": "3126000000",
                    "text": "CALL(312) 600-0000"
                },
                {
                    "href": "tel:3126000000",
                    "phone": "3126000000",
                    "text": "312-600-0000"
                },
                {
                    "href": "tel:3126000000",
                    "phone": "3126000000",
                    "text": "312-600-0000"
                },
                {
                    "href": "tel:8446000000",
                    "phone": "8446000000",
                    "text": "(844) 600-0000"
                },
                {
                    "href": "tel:3124624082",
                    "phone": "3124624082",
                    "text": "(312) 462-4082"
                },
                {
                    "href": "tel:8446000000",
                    "phone": "8446000000",
                    "text": "(844) 600-0000"
                },
                {
                    "href": "tel:2246000000",
                    "phone": "2246000000",
                    "text": "(224) 600-0000"
                },
                {
                    "href": "tel:8726000000",
                    "phone": "8726000000",
                    "text": "(872) 600-0000"
                },
                {
                    "href": "tel:7086000000",
                    "phone": "7086000000",
                    "text": "(708) 600-0000"
                }
            ],
            "logs": {
                "errors_count": 4,
                "warnings_count": 0
            }
        },
        {
            "id": 3673,
            "name": "Ankinlaw Page",
            "url": "https://ankinlaw.com/amazon-warehouse-putting-workers-at-risk/",
            "auto_added": 1,
            "pattern": "/^\\+?[1-9][0-9]{7,14}$/",
            "type": 3,
            "phone": "3126000000",
            "parent_id": 3654,
            "created_at": "2023-04-17T22:40:41.000000Z",
            "updated_at": "2023-04-17T22:40:41.000000Z",
            "links": [
                {
                    "href": "tel:3126000000",
                    "phone": "3126000000",
                    "text": "(312) 600-0000"
                },
                {
                    "href": "tel:3126000000",
                    "phone": "3126000000",
                    "text": "CALL(312) 600-0000"
                },
                {
                    "href": "tel:3126000000",
                    "phone": "3126000000",
                    "text": "312-600-0000"
                },
                {
                    "href": "tel:3126000000",
                    "phone": "3126000000",
                    "text": "312-600-0000"
                },
                {
                    "href": "tel:8446000000",
                    "phone": "8446000000",
                    "text": "(844) 600-0000"
                },
                {
                    "href": "tel:3124624082",
                    "phone": "3124624082",
                    "text": "(312) 462-4082"
                },
                {
                    "href": "tel:8446000000",
                    "phone": "8446000000",
                    "text": "(844) 600-0000"
                },
                {
                    "href": "tel:2246000000",
                    "phone": "2246000000",
                    "text": "(224) 600-0000"
                },
                {
                    "href": "tel:8726000000",
                    "phone": "8726000000",
                    "text": "(872) 600-0000"
                },
                {
                    "href": "tel:7086000000",
                    "phone": "7086000000",
                    "text": "(708) 600-0000"
                }
            ],
            "logs": {
                "errors_count": 4,
                "warnings_count": 0
            }
        },
        {
            "id": 3674,
            "name": "Ankinlaw Page",
            "url": "https://ankinlaw.com/was-your-obgyn-drunk-while-seeing-patients/",
            "auto_added": 1,
            "pattern": "/^\\+?[1-9][0-9]{7,14}$/",
            "type": 3,
            "phone": "3126000000",
            "parent_id": 3654,
            "created_at": "2023-04-17T22:40:41.000000Z",
            "updated_at": "2023-04-17T22:40:41.000000Z",
            "links": [
                {
                    "href": "tel:3126000000",
                    "phone": "3126000000",
                    "text": "(312) 600-0000"
                },
                {
                    "href": "tel:3126000000",
                    "phone": "3126000000",
                    "text": "CALL(312) 600-0000"
                },
                {
                    "href": "tel:3126000000",
                    "phone": "3126000000",
                    "text": "312-600-0000"
                },
                {
                    "href": "tel:3126000000",
                    "phone": "3126000000",
                    "text": "312-600-0000"
                },
                {
                    "href": "tel:8446000000",
                    "phone": "8446000000",
                    "text": "(844) 600-0000"
                },
                {
                    "href": "tel:3124624082",
                    "phone": "3124624082",
                    "text": "(312) 462-4082"
                },
                {
                    "href": "tel:8446000000",
                    "phone": "8446000000",
                    "text": "(844) 600-0000"
                },
                {
                    "href": "tel:2246000000",
                    "phone": "2246000000",
                    "text": "(224) 600-0000"
                },
                {
                    "href": "tel:8726000000",
                    "phone": "8726000000",
                    "text": "(872) 600-0000"
                },
                {
                    "href": "tel:7086000000",
                    "phone": "7086000000",
                    "text": "(708) 600-0000"
                }
            ],
            "logs": {
                "errors_count": 4,
                "warnings_count": 0
            }
        },
        {
            "id": 3654,
            "name": "Ankinlaw",
            "url": "https://ankinlaw.com/",
            "auto_added": null,
            "pattern": "/^\\+?[1-9][0-9]{7,14}$/",
            "type": 1,
            "phone": "3126000000",
            "parent_id": null,
            "created_at": "2023-04-17T22:40:41.000000Z",
            "updated_at": "2023-04-17T22:40:41.000000Z",
            "links": [
                {
                    "href": "tel:3126000000",
                    "phone": "3126000000",
                    "text": "(312) 600-0000"
                },
                {
                    "href": "tel:3126000000",
                    "phone": "3126000000",
                    "text": "CALL(312) 600-0000"
                },
                {
                    "href": "tel:3126000000",
                    "phone": "3126000000",
                    "text": "312-600-0000"
                },
                {
                    "href": "tel:8446000000",
                    "phone": "8446000000",
                    "text": "(844) 600-0000"
                },
                {
                    "href": "tel:3124624082",
                    "phone": "3124624082",
                    "text": "(312) 462-4082"
                },
                {
                    "href": "tel:8446000000",
                    "phone": "8446000000",
                    "text": "(844) 600-0000"
                },
                {
                    "href": "tel:2246000000",
                    "phone": "2246000000",
                    "text": "(224) 600-0000"
                },
                {
                    "href": "tel:8726000000",
                    "phone": "8726000000",
                    "text": "(872) 600-0000"
                },
                {
                    "href": "tel:7086000000",
                    "phone": "7086000000",
                    "text": "(708) 600-0000"
                }
            ],
            "logs": {
                "errors_count": 4,
                "warnings_count": 0
            },
            "phones": [
                "3126000000",
                "8446000000"
            ]
        }
    ],
    "logs": {
        "errors_count": 84,
        "warnings_count": 0
    }
}

const setQueue = (data=[]) => {
	queue = data
}

var defaultFilters = {
	limit:20,
	skip:0
}

var filters = {
	limit:20,
	skip:0
}

const setFilters = flts => {
    filters = {...filters,...flts};
}

const cleanString = (str) => {
	var data = str.replace(/[^a-zA-Z0-9]/g, "")
	return data.replace(/[^0-9]/g, '');
}

const WebsiteIssues = () => {
	

	const [isLoading,setIsLoading] = useState(false)
	const [data,setData] = useState([])
	const [allLinks,setAllLinks] = useState([])
	const [firstRow,setFirstRow] = useState({})
	const [renderQueue,setRenderQueue] = useState(0)
	const [activePage,setActivePage] = useState(1)
	const {id} = useParams()

	const [isInfiniteLoading,setIsInfiniteLoading] = useState(false)
    const [lastElement, setLastElement] = useState(null);

    const observer = useRef(
        new IntersectionObserver(
            (entries) => {
                const first = entries[0];
                if (first.isIntersecting) {
                	setIsInfiniteLoading(true)
                    // fetchInfiniteData()
                }
            })
    );

    useEffect(()=>{

        const currentElement  = lastElement;
        const currentObserver = observer.current;

        if (currentElement) {
            currentObserver.observe(currentElement);
        }

        return () => {
            if (currentElement) {
                currentObserver.unobserve(currentElement);
            }
        }

    },[lastElement])

    useEffect(()=>{

        if(isInfiniteLoading){
            fetchInfiniteData()
        }

    },[isInfiniteLoading])

    const fetchInfiniteData = () => {
		// if(!isInfiniteLoading){
			setIsInfiniteLoading(true)
			CallApi.get(`/websites/${id}/check`,{params:{skip:filters.skip}}).then(res=>{
				setFilters({limit:filters.limit+res.data.total_links,skip:filters.skip+res.data.total_links})
				setAllLinks([...allLinks,...res.data.links])

                if(res.data.total_links>res.data.links.length){
                    fetchInfiniteData(true)
                }

			}).catch(err=>{
				console.log(err)
				alert("error infinite loading...")
			}).finally(()=>{
				setIsInfiniteLoading(false)
			})
		// }
	}

	const fetchData = () => {
		if(!isLoading){
			setIsLoading(true)
			CallApi.get(`/websites/${id}/check`,{params:{limit:filters.limit,skip:filters.skip}}).then(res=>{
				setFilters({limit:filters.limit+res.data.total_links,skip:filters.skip+res.data.total_links})
				setData(res.data.links)
				setAllLinks(res.data.links)
				setFirstRow(res.data)
                if(res.data.total_links>res.data.links.length){
                    setIsInfiniteLoading(true)
                }
			}).catch(err=>{
				alert("error")
			}).finally(()=>{
				setIsLoading(false)
			})
		}
	}

	// useEffect(()=>{
	// 	if(activePage!=1 || (firstRow.current_page && firstRow.current_page!=1)){
	// 		fetchData()
	// 	}
	// },[activePage])

	const printQueueResult = () => {
		setIsLoading(true)
		CallApi.post(`/websites/mail`,{data:queue}).then(res=>{
			setData(res.data)
			setFirstRow(res.data)
		}).catch(err=>{
			alert("error")
		}).finally(()=>{
			setIsLoading(false)
		})
	}


	useEffect(()=>{

		setFilters({...defaultFilters})
		fetchData()
        // setData(ddata.links)
        // setAllLinks(ddata.links)
        // setFirstRow(ddata)

	},[id])

	const getPages = (rows) => {
		var pages = []
		for (var i = 0; i < rows; i++) {
			pages.push(i+1)
		}
		return pages;
	}

	const Pagination = (props) => {
		const {rows,active_page} = props
		// console.log("current_page",active_page)
		return (
			<div className="mjd-pagination" >
				{
					getPages(rows/20).map((row)=>(
						<a href="javascript:void()" className={active_page==row?"active":""} onClick={()=>props.onClick(row)} >{row}</a>
					))
				}
			</div>
		)
	}

	return (
		<div className="websites-container">
			{
				isLoading
				&&<Loading />
			}
			<div className="">
				{
					data.length>0
					?<div style={{textAlign:"center"}} >
						<h1>{firstRow.row.name}</h1>
                        <h3>
                            {
                                firstRow.row.phones.map((phone,index)=>(
                                    <>{ (firstRow.row.phones.length-1) == index  ? ` ${phone} ` : `${phone} | `  }</>
                                ))
                            }
                        </h3>
						{/*<Button onClick={printQueueResult} >Mail Result</Button>*/}
					</div>
					:<></>
				}
			</div>
			{
				allLinks.length>0
				&&<div className="website-check__wrapper" >
					{
						allLinks.map((qu,index)=>(
							<>
								{
									index==(allLinks.length-1)
									?<WebsiteRow qu={qu} firstRow={firstRow} ref={setLastElement} />
									:<WebsiteRow qu={qu} firstRow={firstRow} indexNumber={index} />
								}
							</>
						))
					}


					{
						isInfiniteLoading
						?<div className="infl" >
							<div class="lds-spinner"><div></div><div></div><div></div><div></div><div></div><div></div><div></div><div></div><div></div><div></div><div></div><div></div></div>
							loading more...
						</div>
						:<></>
					}

					{/*<Pagination active_page={firstRow.current_page} onClick={(page)=>setActivePage(page)} rows={firstRow.total_rows} />*/}

				</div>
			}

		</div>
	)
}

const inArray = (needle,haystack) => {
    let find = haystack.find(row=> row == needle)
    if(find){
        return true;
    }
    return false;
}

const WebsiteRow = React.forwardRef((props,ref) => {
		const {qu,indexNumber,firstRow} = props;
		const {links} = qu;

		const hasIssue = () => {
			let yes = false;
			links.map(link=> {
				if(!inArray(link.phone,firstRow.row.phones) || link.phone!=cleanString(link.text) || !inArray(cleanString(link.text),firstRow.row.phones) ){
					yes = true;
				}
			})

			return yes;
		}

		return (
			<>
			{
					hasIssue()
					&&<div className="website-check-log__row" ref={ref?ref:null} >
						<div className="website-check__row">
							{/*<div className="website-check__col website-check__colId">{qu.id}</div>*/}
							{/*<div className="website-check__col website-check__colName website-check__colFree">{qu.name}</div>*/}
							<div className="website-check__col website-check__col100">{qu.url}</div>
							{/*<div className="website-check__col website-check__col50">{qu.phone}</div>*/}
						</div>
						{
							hasIssue()
							?<div className="website-check__errors"  >
								
								{
									links.map((link,index)=>(
										<>
										{
											( !inArray(link.phone,firstRow.row.phones) || link.phone!=cleanString(link.text) || !inArray(cleanString(link.text),firstRow.row.phones))
											?
											<>
												<div>

													<div className="website-check-log__links" >
														<div><span>href</span> <a href={"tel:"+link.phone}>{link.phone}</a></div>
														<div><span>text</span> {link.text}</div>
														{/*<div>{link.href}</div>*/}
													</div>

													{
                                                        !inArray(link.phone,firstRow.row.phones)
														?<div className="website-check-log__logMsg website-check-log__errorMsg" >
															Website Phones and link href is different
														</div>
														:<></>
													}
													{
														link.phone!=cleanString(link.text)
														?<div className="website-check-log__logMsg website-check-log__warningMsg" >
                                                            Phone href and link text is different
                                                        </div>
														:<></>
													}
													{
                                                        !inArray(cleanString(link.text),firstRow.row.phones)
														?<div className="website-check-log__logMsg website-check-log__warningMsg" >
                                                            Website Phones and link text is different {cleanString(link.text)}
                                                        </div>
														:<></>
													}

												</div>
											</>
											:<></>
											}
										</>
									))
								}

							</div>
							:<div  className="website-check-log__logMsg website-check-log__noIssueMsg" >No Issue</div>
						}
					</div>

			}
			</>
		)
	})

export default WebsiteIssues;