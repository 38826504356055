import axios from 'axios';

export const CallApi = axios.create({
  // baseURL: 'http://10.10.10.245:8000/',
  // baseURL: 'https://mwpvs.intotechgroup.com/public/',
  baseURL: 'https://mjdbackend-wv9oi.kinsta.app/',
  // baseURL: 'https://mwpvs.kinsta.cloud/api/public/index.php/',
  // timeout: 1000,
  // headers: {}
});
