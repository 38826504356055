import React,{useEffect,useState} from 'react';
import {CallApi} from './../helpers';
import Select from 'react-select'
import {Loading} from './../components';
import {Link} from 'react-router-dom';

const WebsitesSelect = (props) => (
  <Select {...props} />
)

const types = ["","Website","PPC","Page"];


const WebsitesList = () => {

	const [websitesOptions,setWebsitesOptions] = useState([])
	const [isLoading,setIsLoading] = useState(true)
	const [data,setData] = useState({})
	const [allData,setAllData] = useState()
	const [showModal,setShowModal] = useState(false)
	const [modalData,setModalData] = useState([])

	const fetchData = () => {
		setIsLoading(true)
		CallApi.get("/websites",{params:{types:[1]}}).then(res=>{
			setData(res.data)
			setAllData(res.data)
		}).catch(err=>{
		}).finally(()=>{
			setIsLoading(false)
		})
	}

	const serach = (a) => {
		const result = allData.filter(row=> (row.url.indexOf(a) > -1) ||  (row.name.indexOf(a) > -1) || (row.type==a) )
		setData(result)
	}

	useEffect(()=>{
		fetchData()
	},[])


	const deleteWebsite = (id) => {

		if(window.confirm("Are you sure ?")){
			setIsLoading(true)
			CallApi.delete(`/websites/${id}/delete`).then(res=>{
				fetchData()
			}).catch(err=>{
				alert(0)
			}).finally(()=>{
				setIsLoading(false)
			})

		}
	}

	return (
		<div className="websites-container">
			{
				isLoading
				&&<Loading />
			}
			
			
				
				<div className="table-data" >
				{
					(allData && allData.length>0)
					&&<input placeholder="Search..." className="mjd-input" onChange={(e)=>serach(e.target.value)} style={{width:"95%"}} />
				}
				{
				(data && data.length>0)
				&&<table>
							<thead>
								<tr>
									<th>#</th>
									<th>Name</th>
									<th>Url</th>
									<th>Phone</th>
									<th>Type</th>
									<th></th>
								</tr>
							</thead>

							{
								data.map((row,id)=>(
									<tr>
										<td>{id+1}</td>
										<td>{row.name}</td>
										<td>{row.url}</td>
										<td>
											<div className={row.phones.length > 1 ? "auto-collapse" : "" } >
												{
													row.phones.map(phone=>(
														<div>{phone.phone}</div>
													))
												}
											</div>
										</td>
										<td>{types[row.type]}</td>
										<td>
											<a href="javascript:void()" onClick={()=>deleteWebsite(row.id)} >Delete </a>
											<Link to={`/edit/${row.id}`} > | Edit</Link> | <a href="javascript:void()" onClick={()=>{
											
												setModalData(row.pages)
												setShowModal(true)

											}} >Pages</a>
										</td>
									</tr>
								))
							}

					</table>
				}
				</div>
			

				{
					showModal
					&&<div className="mjd-modal" >
							
							<div className="mjd-modal__content">
								
								<div className="mjd-modal__close" onClick={()=>setShowModal(false)} >Close</div>

								<div className="table-data">
								{
									(modalData && modalData.length>0)
									&&<table>
												<thead>
													<tr>
														<th>#</th>
														<th>Name</th>
														<th>Url</th>
														<th>Phone</th>
														<th>Type</th>
														<th></th>
													</tr>
												</thead>

												{
													modalData.map((row,id)=>(
														<tr>
															<td>{id+1}</td>
															<td>{row.name}</td>
															<td>{row.url}</td>
															<td>{row.phone}</td>
															<td>{types[row.type]}</td>
															<td>
																<a href="javascript:void()" onClick={()=>deleteWebsite(row.id)} >Delete </a>
																<Link to={`/edit/${row.id}`} > | Edit</Link>
															</td>
														</tr>
													))
												}

										</table>
									}
									</div>

							</div>

					</div>
				}

		</div>
	)
}

export default WebsitesList;